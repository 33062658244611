<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Omni Store
                  <!-- <v-spacer></v-spacer>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        solo
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col> -->
                  <v-row justify="end" class="px-5">
                    <v-dialog
                      :retain-focus="false"
                      v-model="dialog"
                      persistent
                      max-width="600px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addcat" ref="addcat">
                          <v-card-title>
                            <span class="headline">Add Omni Store</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="organization"
                                    label="Organization"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="firstname"
                                    label="First Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="lastname"
                                    label="Last Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="email"
                                    label="Email"
                                    :rules="emailRules"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="phone"
                                    label="Phone"
                                    :rules="phoneRules"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="pincode"
                                    label="Pincode"
                                    :rules="pinRules"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <v-file-input
                                    v-model="categoryImg"
                                    @change="getFiles"
                                    label="Image"
                                    outlined
                                    dense
                                  ></v-file-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogclose()"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!addcat"
                              @click="categoryadd()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="category"
                  :search="search"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span>{{ item.firstname }} {{ item.lastname }}</span>
                  </template>
                  <template v-slot:[`item.image`]="{ item }">
                    <img
                      v-bind:src="baseURL + item.image"
                      width="auto"
                      height="50px"
                    />
                  </template>
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon small class="mr-2" @click="sellerinfo(item._id)">
                      mdi-eye
                    </v-icon>
                    <!-- <v-icon small class="mr-2" @click="editcat(item)">
                        mdi-pencil
                      </v-icon> -->
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this Omni
                          Store?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:top>
                    <v-dialog
                      v-model="editdialog"
                      :retain-focus="false"
                      max-width="600px"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Omni Store </span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.firstname"
                                  label="First Name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.lastname"
                                  label="Last Name"
                                  :rules="[rules.required]"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.email"
                                  label="Email"
                                  :rules="[rules.required]"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.phone"
                                  label="Phone"
                                  :rules="[rules.required]"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.pincode"
                                  label="Pincode"
                                  :rules="[rules.required]"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-img
                                  height="150"
                                  width="200"
                                  contain
                                  :src="baseURL + editingitem.image"
                                >
                                </v-img>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-file-input
                                  v-model="categoryImg"
                                  @change="getFiles"
                                  label="Image"
                                  outlined
                                  dense
                                ></v-file-input>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="editdialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="edit()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
                <div class="pt-2" v-if="pages > 1">
                  <v-pagination
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#FF0000"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      dialog: false,
      editdialog: false,
      editingitem: {},
      name: "",
      listingOrder: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 12,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      items: ["meta"],
      keywordItems: ["Best Selling", "User Friendly"],
      seotags: null,
      title: null,
      fbpixel: null,
      keywords: [],
      categoryImg: null,
      cId: "",
      firstname: "",
      lastname: "",
      email: "",
      pincode: "",
      phone: "",
      organization: "",
      attachments: null,
      attachmentsformData: new FormData(),
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Pincode", value: "pincode" },
        { text: "Image", value: "image" },
        // { text: "Username", value: "username" },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      emailRules: [
        (v) => !!v || "Email is Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone Number is Required",
        (v) => (v && v.length <= 10) || "Number must be less than 11",
        (v) => (v && v.length >= 10) || "Number must be grater than 10",
        (v) => /^\d{10}$/.test(v) || "Phone must be number",
      ],
      pinRules: [
        (v) => !!v || "Pincode is Required",
        (v) => /^(\d{4}|\d{6})$/.test(v) || "Pincode must be valid",
      ],
      category: [],
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.admin = [];
    },
    getFiles() {
      var selectedFiles = this.categoryImg;
      if (this.categoryImg) {
        this.msg = null;
        this.attachments = selectedFiles;
      }
    },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/omnistore/delete",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/omnistore/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    categoryadd() {
      var data = {};
      data["organization"] = this.organization;
      data["firstname"] = this.firstname;
      data["lastname"] = this.lastname;
      data["email"] = this.email;
      data["pincode"] = this.pincode;
      data["phone"] = this.phone;
      data["type"] = "OmniStore";
      axios({
        url: "/user/create/omnistore",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cId = response.data.data.id;
            if (this.categoryImg) {
              this.uploadattachments();
            }
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadattachments() {
      this.categoryImg = null;
      this.attachmentsformData.append("image", this.attachments);
      this.attachmentsformData.append("id", this.cId);
      axios({
        method: "POST",
        url: "/user/omnistore/add/image",
        data: this.attachmentsformData,

        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attachmentsformData = new FormData();
            this.msg = "Upload Successfully";
            this.showsnackbar = true;
            var myTimeout = setTimeout(this.getData, 2000);
            console.log(myTimeout);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["firstname"] = this.editingitem.firstname;
      data["lastname"] = this.editingitem.lastname;
      data["email"] = this.editingitem.email;
      data["pincode"] = this.editingitem.pincode;
      data["phone"] = this.editingitem.phone;
      data["organization"] = this.organization;
      data["type"] = "OmniStore";
      axios({
        url: "/user/Edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            if (this.categoryImg) {
              //   this.attachmentsformData.append("icon", this.attachments);
              this.uploadattachments1();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadattachments1() {
      this.categoryImg = null;
      this.attachmentsformData.append("image", this.attachments);
      this.attachmentsformData.append("id", this.editingitem._id);
      axios({
        method: "POST",
        url: "/user/omnistore/add/image",
        data: this.attachmentsformData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attachmentsformData = new FormData();
            this.msg = "Upload Successfully";
            this.showsnackbar = true;
            var myTimeout = setTimeout(this.getData, 2000);
            console.log(myTimeout);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    sellerinfo(id) {
      this.$router.push("/ominiStoreDetails?id=" + id);
    },
  },
};
</script>
  
  <style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
  